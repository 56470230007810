import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import FolderIcon from '@mui/icons-material/Folder';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import GetAppIcon from '@mui/icons-material/GetApp';
import {Tooltip} from "@mui/material";

export default function LabelBottomNavigation() {
    const [value, setValue] = React.useState('recents');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <BottomNavigation sx={{ width: 500, margin: "auto", display: 'flex',  justifyContent:'between', alignItems:'center' }} value={value} onChange={handleChange}>
                <BottomNavigationAction label="Zuletzt" value="recents" icon={<RestoreIcon />}/>
                <BottomNavigationAction label="Favoriten" value="favorites" icon={<FavoriteIcon />}/>
                <BottomNavigationAction label="Position" value="nearby" icon={<LocationOnIcon />}/>
                <BottomNavigationAction label="Dateien" value="files" icon={<FolderIcon />} />
                <BottomNavigationAction label="Transfer" value="transfer" icon={<SyncAltIcon />} />
                <BottomNavigationAction label="Export" value="export" icon={<GetAppIcon />} />
        </BottomNavigation>
    );
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Hmi from "./xws/layout/hmi";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Hmi Customer="Erlenbach Maschinen" Title="REACT HMI Demo" />
  </React.StrictMode>
);
import * as React from 'react';
import Container from '@mui/material/Container';

import {ButtonGroup, Stack, ToggleButtonGroup, Tooltip} from "@mui/material";
import MuiToggleButton from "@mui/material/ToggleButton";
import { styled } from "@mui/material/styles";

import Grow, { GrowProps } from '@mui/material/Grow';
import Snackbar from '@mui/material/Snackbar';
import { TransitionProps } from '@mui/material/transitions';

import { Grid } from '@mui/material';
import MenuAppBar from "./header2";
import LinearDeterminate from "./lineardeterminate";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import LabelBottomNavigation from "./footer";
import ControlledTabs from "./settings";
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const centerContainer = {
    display: 'flex',
    justifyContent:'center',
    alignItems:'center'
};

const theme = createTheme({
    palette: {
        primary: {
            main: '#d1001d'
        }
    },
    status: {
        danger: '#00fff0',
    },
});

declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string;
        };
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
}

export interface SnackbarMessage {
    message: string;
    key: number;
}

export interface State {
    open: boolean;
    snackPack: readonly SnackbarMessage[];
    messageInfo?: SnackbarMessage;
}

export default function Hmi ({Customer, Title} : {Customer: string, Title: string}) {

    const [operationMode, setOperationMode] = React.useState<string | null>('Automatisch');
    const [material, setMaterial] = React.useState<string | null>('Standard');

    const [snackPack, setSnackPack] = React.useState<readonly SnackbarMessage[]>([]);
    const [open, setOpen] = React.useState(false);
    const [messageInfo, setMessageInfo] = React.useState<SnackbarMessage | undefined>(
        undefined,
    );

    React.useEffect(() => {
        if (snackPack.length && !messageInfo) {
            // Set a new snack when we don't have an active one
            setMessageInfo({ ...snackPack[0] });
            setSnackPack((prev) => prev.slice(1));
            setOpen(true);
        } else if (snackPack.length && messageInfo && open) {
            // Close an active snack when a new one is added
            setOpen(false);
        }

    }, [snackPack, messageInfo, open]);

    const handleExited = () => {
        setMessageInfo(undefined);
    };

    const [state, setState] = React.useState<{
        open: boolean;
        Transition: React.ComponentType<
            TransitionProps & {
            children: React.ReactElement<any, any>;
        }
            >;
    }>({
        open: false,
        Transition: Grow,
    });


    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };


        const handleChangeOperationMode = (
        event: React.MouseEvent<HTMLElement>,
        newOperationMode: string,
    ) => {
        if(newOperationMode == null)
            return;

        if(state.open)
            setState({
                ...state,
                open: false,
            });

        setOperationMode(newOperationMode);
        setSnackPack((prev) => [...prev, { message: `Betriebsmodus: ${newOperationMode}`, key: new Date().getTime() }]);
    };

    const ToggleButton = styled(MuiToggleButton)({
        "&.Mui-selected, &.Mui-selected:hover": {
            color: "white",
            backgroundColor: theme.palette.primary.main,
        }
    });

    const handleChangeMaterial = (
        event: React.MouseEvent<HTMLElement>,
        newMaterial: string,
    ) => {
        if(newMaterial == null)
            return;

        if(state.open)
            setState({
                ...state,
                open: false,
            });

        setMaterial(newMaterial);
        setSnackPack((prev) => [...prev, { message: `Material: ${newMaterial}`, key: new Date().getTime() }]);
    };


    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="md" sx={{my: 2, py: 1, px: "16px !important", border: 3, borderColor: "primary.main"}} >
                    {/*<h2 style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>{Customer} - {Title}</h2>*/}
                <MenuAppBar></MenuAppBar>
                <Grid container rowSpacing={6} columnSpacing={1}>
                    <Grid item xs={6}>
                        <Stack style={centerContainer}>
                            <h2>Betriebsmodus</h2>
                            <ToggleButtonGroup
                                value={operationMode}
                                exclusive
                                onChange={handleChangeOperationMode}
                                >
                                <ToggleButton value="Automatisch">Automatisch</ToggleButton>
                                <ToggleButton value="Manuell">Manuell</ToggleButton>
                                <ToggleButton value="Einzelmodus">Einzelmodus</ToggleButton>
                            </ToggleButtonGroup>
                        </Stack>
                    </Grid>

                    <Grid item xs={6}>
                        <Stack style={centerContainer}>
                            <h2>Material</h2>
                            <ToggleButtonGroup
                                value={material}
                                exclusive
                                onChange={handleChangeMaterial}
                            >
                                <ToggleButton value="Standard">Standard</ToggleButton>
                                <ToggleButton value="Premium">Premium</ToggleButton>
                                <ToggleButton value="Spezial">Spezial</ToggleButton>
                            </ToggleButtonGroup>
                        </Stack>
                    </Grid>

                    <Grid item xs={12}>
                        <LinearDeterminate></LinearDeterminate>
                    </Grid>

                    <Grid item xs={12}>
                        <ControlledTabs Title="Einstellungen"></ControlledTabs>
                    </Grid>

                    {/*<Grid item xs={12}>*/}

                    {/*    <Button variant="contained" color="error" onClick={handleOpen}>Fehlerliste</Button>*/}
                    {/*    <Modal*/}
                    {/*        open={open}*/}
                    {/*        onClose={handleClose}*/}
                    {/*        aria-labelledby="modal-modal-title"*/}
                    {/*        aria-describedby="modal-modal-description"*/}
                    {/*    >*/}
                    {/*        <Box sx={style}>*/}
                    {/*            <Typography id="modal-modal-title" variant="h6" component="h2">*/}
                    {/*                Fehlerliste*/}
                    {/*            </Typography>*/}
                    {/*            <Typography id="modal-modal-description" sx={{ mt: 2 }}>*/}
                    {/*                Liste mit Fehlern der Maschine.*/}
                    {/*            </Typography>*/}
                    {/*        </Box>*/}
                    {/*    </Modal>*/}

                    {/*</Grid>*/}

                    <Grid item xs={12}>
                        <LabelBottomNavigation></LabelBottomNavigation>
                    </Grid>
                </Grid>

                <Snackbar
                    open={open}
                    anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                    sx={{ height: "9%" , left: "35.6% !important"}}
                    TransitionComponent={Grow}
                    autoHideDuration={2000}
                    TransitionProps={{ onExited: handleExited }}
                    onClose={handleClose}
                    key={state.Transition.name}
                >
                    <Alert onClose={handleClose} severity="success" sx={{ width: '280px'}}>
                        {messageInfo ? messageInfo.message : undefined}
                    </Alert>
                </Snackbar>
            </Container>
        </ThemeProvider>
    );
}
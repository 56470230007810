import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

function valuetext(value: number) {
    return `${value}°C`;
}

export default function DiscreteSliderLabel2({Title} : {Title: string}) {
    return (
        <Box sx={{ width: 300, m: 2 }}>
            <h2>{Title}</h2>
            <Slider defaultValue={50} aria-label="Default" valueLabelDisplay="on" />
        </Box>
    );
}
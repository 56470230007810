import * as React from 'react';
import Typography from '@mui/material/Typography';
import {FormControl, FormLabel, RadioGroup, Stack} from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import SpeedIcon from '@mui/icons-material/Speed';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from '@mui/material/Radio';
import DiscreteSliderLabel from "./discreteslider";
import DiscreteSliderLabel2 from "./discreteslider2";
import TransferList from "./transferlist";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import BuildCircle from '@mui/icons-material/BuildCircle';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListSubheader from '@mui/material/ListSubheader';
import Chip from '@mui/material/Chip';



const centerContainer = {
    display: 'flex',
    justifyContent:'center',
    alignItems:'center',
};

export default function ControlledTabs({Title} : {Title: string}) {

    const [value, setValue] = React.useState(0);
    const [checked, setChecked] = React.useState(['wifi']);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleToggle = (value: string) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    interface TabPanelProps {
        children?: React.ReactNode;
        index: number;
        value: number;
    }

    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                style={{height: 350}}
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 1 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <Paper sx={{ margin: "auto"}} elevation={1} >
            <Tabs sx={{ borderBottom: "1px solid", borderBottomColor : "primary.main"}} value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                <Tab icon={<SettingsIcon />} label="Allgemeine Einstellungen" {...a11yProps(0)} />
                <Tab icon={<AutorenewIcon />} label="Maschinen" {...a11yProps(1)} />
                <Tab icon={<SpeedIcon />} label="Geschwindigkeit" {...a11yProps(2)} />
                <Tab icon={<AutorenewIcon />} label="Produktionszyklus" {...a11yProps(3)} />
            </Tabs>

            <TabPanel value={value} index={0}>
                        <FormControl sx={{m: 2}} style={centerContainer}>
                            <FormLabel id="demo-row-radio-buttons-group-label">Füllmenge</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                defaultValue="med"
                            >
                                <FormControlLabel value="max" control={<Radio />} label="Maximum" />
                                <FormControlLabel value="med" control={<Radio />} label="Mittel" />
                                <FormControlLabel value="min" control={<Radio />} label="Minimum" />
                            </RadioGroup>
                        </FormControl>
                        <FormControl sx={{m: 2}} style={centerContainer}>
                            <FormLabel id="demo-row-radio-buttons-group-label">Betriebsdruck</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                defaultValue="med"
                            >
                                <FormControlLabel value="max" control={<Radio />} label="Hoch" />
                                <FormControlLabel value="med" control={<Radio />} label="Mittel" />
                                <FormControlLabel value="min" control={<Radio />} label="Niedrig" />
                            </RadioGroup>
                        </FormControl>
            </TabPanel>
            <TabPanel  value={value} index={1}>
                <FormControl sx={{m: 2}} style={centerContainer}>
                    <List
                        sx={{
                            width: '100%',
                            maxWidth: 500,
                            bgcolor: 'background.paper',
                            overflow: 'auto',
                            maxHeight: 300, }}
                        subheader={<ListSubheader sx={{ fontWeight: "bold"}}>Maschinen</ListSubheader>}
                    >
                        <ListItem>
                            <ListItemIcon>
                                <BuildCircle color="success" />
                            </ListItemIcon>
                            <ListItemText id="switch-list-label-m1" primary="Maschine-1" />
                            <Chip sx={{width: "40%", margin: "auto"}} color="success" label="Keine Fehler vorhanden !" variant="filled" />
                            <Switch
                                edge="end"
                                onChange={handleToggle('m1')}
                                checked={checked.indexOf('m1') !== -1}
                                inputProps={{
                                    'aria-labelledby': 'switch-list-label-wifi',
                                }}
                            />
                        </ListItem>
                        <ListItem >
                            <ListItemIcon>
                                <BuildCircle color="error" />
                            </ListItemIcon>
                            <ListItemText id="switch-list-label-m2" primary="Maschine-2" />
                            <Chip sx={{width: "40%", margin: "auto"}} color="error" label="Keine Verbindung !" variant="filled" />
                            <Switch
                                edge="end"
                                onChange={handleToggle('m2')}
                                checked={checked.indexOf('m2') !== -1}
                                inputProps={{
                                    'aria-labelledby': 'switch-list-label-bluetooth',
                                }}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <BuildCircle color="success" />
                            </ListItemIcon>
                            <ListItemText id="switch-list-label-m3" primary="Maschine-3" />
                            <Chip sx={{width: "40%", margin: "auto"}} color="success" label="Keine Fehler vorhanden !" variant="filled" />
                            <Switch
                                edge="end"
                                onChange={handleToggle('m3')}
                                checked={checked.indexOf('m3') !== -1}
                                inputProps={{
                                    'aria-labelledby': 'switch-list-label-bluetooth',
                                }}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <BuildCircle color="success" />
                            </ListItemIcon>
                            <ListItemText id="switch-list-label-m4" primary="Maschine-4" />
                            <Chip sx={{width: "40%", margin: "auto"}} color="success" label="Keine Fehler vorhanden !" variant="filled" />
                            <Switch
                                edge="end"
                                onChange={handleToggle('m4')}
                                checked={checked.indexOf('m4') !== -1}
                                inputProps={{
                                    'aria-labelledby': 'switch-list-label-bluetooth',
                                }}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <BuildCircle color="success" />
                            </ListItemIcon>
                            <ListItemText id="switch-list-label-m5" primary="Maschine-5" />
                            <Chip sx={{width: "40%", margin: "auto"}} color="success" label="Keine Fehler vorhanden !" variant="filled" />
                            <Switch
                                edge="end"
                                onChange={handleToggle('m5')}
                                checked={checked.indexOf('m5') !== -1}
                                inputProps={{
                                    'aria-labelledby': 'switch-list-label-bluetooth',
                                }}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <BuildCircle color="success" />
                            </ListItemIcon>
                            <ListItemText id="switch-list-label-m6" primary="Maschine-6" />
                            <Chip sx={{width: "40%", margin: "auto"}} color="success" label="Keine Fehler vorhanden !" variant="filled" />
                            <Switch
                                edge="end"
                                onChange={handleToggle('m6')}
                                checked={checked.indexOf('m6') !== -1}
                                inputProps={{
                                    'aria-labelledby': 'switch-list-label-bluetooth',
                                }}
                            />
                        </ListItem>
                    </List>
                </FormControl>
            </TabPanel>
            <TabPanel  value={value} index={2}>
                <FormControl sx={{m: 2}} style={centerContainer}>
                    <TransferList />
                </FormControl>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <FormControl sx={{m: 2}} style={centerContainer}>
                    <DiscreteSliderLabel Title="Temperatur"/>
                    <DiscreteSliderLabel2 Title="Druck"/>
                </FormControl>
            </TabPanel>

            {/*<h2 style={centerContainer}>{Title}</h2>*/}
            {/*<Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>*/}
            {/*    <AccordionSummary*/}
            {/*        expandIcon={<ExpandMoreIcon />}*/}
            {/*        aria-controls="panel1bh-content"*/}
            {/*        id="panel1bh-header"*/}
            {/*    >*/}
            {/*        <Typography sx={{ width: '33%', flexShrink: 0 }}>*/}
            {/*            Allgemeine Einstellungen*/}
            {/*        </Typography>*/}
            {/*        <SettingsIcon />*/}
            {/*    </AccordionSummary>*/}
            {/*    <AccordionDetails>*/}
            {/*        <FormControl sx={{m: 2}} style={centerContainer}>*/}
            {/*            <FormLabel id="demo-row-radio-buttons-group-label">Füllmenge</FormLabel>*/}
            {/*            <RadioGroup*/}
            {/*                row*/}
            {/*                aria-labelledby="demo-row-radio-buttons-group-label"*/}
            {/*                name="row-radio-buttons-group"*/}
            {/*                defaultValue="med"*/}
            {/*            >*/}
            {/*                <FormControlLabel value="max" control={<Radio />} label="Maximum" />*/}
            {/*                <FormControlLabel value="med" control={<Radio />} label="Mittel" />*/}
            {/*                <FormControlLabel value="min" control={<Radio />} label="Minimum" />*/}
            {/*            </RadioGroup>*/}
            {/*        </FormControl>*/}
            {/*        <FormControl sx={{m: 5}} style={centerContainer}>*/}
            {/*            <FormLabel id="demo-row-radio-buttons-group-label">Betriebsdruck</FormLabel>*/}
            {/*            <RadioGroup*/}
            {/*                row*/}
            {/*                aria-labelledby="demo-row-radio-buttons-group-label"*/}
            {/*                name="row-radio-buttons-group"*/}
            {/*                defaultValue="med"*/}
            {/*            >*/}
            {/*                <FormControlLabel value="max" control={<Radio />} label="Hoch" />*/}
            {/*                <FormControlLabel value="med" control={<Radio />} label="Mittel" />*/}
            {/*                <FormControlLabel value="min" control={<Radio />} label="Niedrig" />*/}
            {/*            </RadioGroup>*/}
            {/*        </FormControl>*/}
            {/*    </AccordionDetails>*/}
            {/*</Accordion>*/}
            {/*<Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>*/}
            {/*    <AccordionSummary*/}
            {/*        expandIcon={<ExpandMoreIcon />}*/}
            {/*        aria-controls="panel2bh-content"*/}
            {/*        id="panel2bh-header"*/}
            {/*    >*/}
            {/*        <Typography sx={{ width: '33%', flexShrink: 0 }}>Geschwindigkeit</Typography>*/}
            {/*        <SpeedIcon />*/}
            {/*    </AccordionSummary>*/}
            {/*    <AccordionDetails>*/}
            {/*        <FormControl sx={{m: 2}} style={centerContainer}>*/}
            {/*            <TransferList />*/}
            {/*        </FormControl>*/}
            {/*    </AccordionDetails>*/}
            {/*</Accordion>*/}
            {/*<Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>*/}
            {/*    <AccordionSummary*/}
            {/*        expandIcon={<ExpandMoreIcon />}*/}
            {/*        aria-controls="panel3bh-content"*/}
            {/*        id="panel3bh-header"*/}
            {/*    >*/}
            {/*        <Typography sx={{ width: '33%', flexShrink: 0 }}>*/}
            {/*            Produktionszyklus*/}
            {/*        </Typography>*/}
            {/*        <AutorenewIcon />*/}
            {/*    </AccordionSummary>*/}
            {/*    <AccordionDetails>*/}
            {/*        <FormControl sx={{m: 2}} style={centerContainer}>*/}
            {/*            <DiscreteSliderLabel Title="Temperatur"/>*/}
            {/*            <DiscreteSliderLabel2 Title="Druck"/>*/}
            {/*        </FormControl>*/}
            {/*    </AccordionDetails>*/}
            {/*</Accordion>*/}
        </Paper >
    );
}